module.exports = {
  //-- SITE SETTINGS -----
  author: "Juni Nguyen",
  siteTitle: "Hello Juni Nguyen",
  siteShortTitle: "juniNguyen.", // Used as logo text in header, footer, and splash screen
  siteDescription: "Juni Nguyen's Portfolio",
  siteUrl: "https://portfolio.juninguyen.tech/",
  siteLanguage: "en_US",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "Juni Nguyen's Portfolio", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#2d334a",
      secondary: "#fbdd74",
      tertiary: "#fbdd74",
      text: "#94a1b2",
      subtext: "#f582ae",
      background: "#fffffe",
      card: "#67568c",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#2d334a",
      secondary: "#fbdd74",
      tertiary: "#fbdd74",
      text: "#94a1b2",
      subtext: "#f582ae",
      background: "#fffffe",
      card: "#67568c",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "Ubuntu, sans-serif",
  },

  //-- ARTICLES SECTION SETTINGS -----
  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@juninguyen",
  shownArticles: 3,

  //-- SOCIAL MEDIA SETTINGS -----
  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, Behance, E-Mail
  socialMedia: [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/juninguyen/",
    },
    {
      name: "Medium",
      url: "https://juninguyen.medium.com/",
    },
    {
      name: "Fiverr",
      url: "https://www.fiverr.com/juninguyen",
    },
    {
      name: "Gumroad",
      url: "https://codingleaf.gumroad.com/",
    },
  ],
  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "About Me",
        url: "/#about",
      },
      {
        name: "Projects",
        url: "/#projects",
      },
      {
        name: "Contact",
        url: "/#contact",
      },
    ],
    button: {
      useFileName: false,
      name: "Resume",
      fileName: "", // the file has to be placed inside the static folder at the root level
      url: "https://gitconnected.com/juniHub/resume", // if useFileName=false, you can set an anchor link here and use the button for navigational purposes
    },
  },
}
